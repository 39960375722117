html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  color: black;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
}

html,
body,
.app {
  height: 100%;
}

.app {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding: 2em 0 2.25em 0;
}

.marquee {
  position: relative;
  width: 100%;
  vertical-align: middle;
  letter-spacing: 0.0125em;
}

.crawler {
  position: absolute;
  top: 0;
  left: 100%;
}
